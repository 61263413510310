import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import HomeManagerDetails from './ServicesDetailsFaq/HomeManagerDetails';

const HomeManager = () => {
    return (
        <>
            <Navigation />
            <HomeManagerDetails />
            <Footer />
        </>
    );
};

export default HomeManager;