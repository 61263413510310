import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { CgCheckO } from 'react-icons/cg';

const HomeManagerDetails = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen}
        videoId="o4GuSJYSzrY" onClose={() => setOpen(false)} />

      <section className="tp-service-details-area pt-120 pb-90 bg-gray-light">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="tp-service-details">
                <h2 className="tp-section-title heading-color-black pt-10 mb-20">Професионален домоуправител</h2>
                <h5 class="tp-section-subtitle-three mb-20">От 4.99лв. на Апартамент</h5>
                <p className="mb-20">Цената за всяка етажна собственост се определя според броя на апартаментите, обема и конкретните дейности по поддръжката, след направено запитване. За подробна оферта, моля свържете се с нас.</p>
                <p className="mb-35">За апартаменти с живущи пенсионери и хора в неравностойно положение – от 3,99лв.</p>
                <h3 className="tp-service-details-subtitle">Администрация и управление</h3>
                <p className="d-flex"> <i > <CgCheckO /> </i> Професионален домоуправител, който отговаря за сградата.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Организиране на общите събрания на етажната собственост и водене на домова книга.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Съдействие за изготвянето на Правилник за вътрешния ред.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Представляваме Етажната собственост пред трети лица (администрация, ЧЕЗ Електроразпределение и други).</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Следим за изпълнението на взетите решения на Общо събрание.</p>

                <h3 className="tp-service-details-subtitle pt-30">Финанси и софтуер</h3>
                <p className="d-flex"> <i > <CgCheckO /> </i> Определяне на месечни вноски за поддръжка и управление на общите части за всеки самостоятелен обект в сградата, съгласно приетите решения от Общо събрание.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Събиране на месечните вноски по метод удобен за вас – Easy Pay, ePay или по банков път.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Разплащане в срок на режийни разходи и дължими сметки за общи части – ел. енергия, вода, топлоподаване, асансьорни сервизи и други.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Управление на фонд‚ Ремонт и обновление.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Отчети и онлайн достъп до портал, в който да проверявате състоянието на общата каса и направените разходи. Предоставяне на информацията и на табло във входа.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Управление на бюджета на входа.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Събиране на просрочени задължения.</p>

                <h3 className="tp-service-details-subtitle pt-30">Юридическа услуга</h3>
                <p className="d-flex"> <i > <CgCheckO /> </i> Процедура по събиране на просрочени вноски от собственици в Етажната Собственост по съдебен път.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Процедура по събиране на стари легитимни вземания към Етажната Собственост.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Осигуряваме юридическа защита интересите на Етажната собственост чрез представителство пред трети лица и пред съда, като подаваме искове, заявления, жалби и др.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeManagerDetails;