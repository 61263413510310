import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import CleaningDetails from './ServicesDetailsFaq/CleaningDetails';

const Cleaning = () => {
    return (
        <>
            <Navigation />
            <CleaningDetails />
            <Footer />
        </>
    );
};

export default Cleaning;