import React from 'react';
import ProjectItem from '../../../components/ProjectItem/ProjectItem';

const DocumentComponent = () => {
   return (
      <>
         <div className="tp-prjects-area pt-120 pb-120">
            <div className="container">
               <div className="row"></div>
               <div className="row pb-50">
                  <div className="col-12">
                     <div className="tp-prjects-tab-content">
                        <div className="tab-content" id="myTabContent">
                           <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                              <div className="row">
                                 <ProjectItem project_subtitle="Образец"
                                    project_title="Споразумение за създаване на собствениците"></ProjectItem>

                                 <ProjectItem project_subtitle="Образец"
                                    project_title="Правилник за вътрешен ред"></ProjectItem>

                                 <ProjectItem project_subtitle="Образец"
                                    project_title="Книга етажна собственост"></ProjectItem>

                                 <ProjectItem project_title="Закон за управление на етажната собственост"></ProjectItem>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default DocumentComponent;