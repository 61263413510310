import React from 'react';

const Footer = () => {
   return (
      <>
         <footer className="theme-dark-bg">
            {/* <div className="tp-footer-area-two pt-20 pb-50">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp" data-wow-delay=".3s">
                           <div className="tp-footer-info">
                              <div className="tp-footer-info-logo mb-35">
                                 <Link to="/"><img src="assets/img/logo/logo-white.png" className="img-fluid" alt="img not found"/></Link>
                              </div>
                              <h4 className="mb-15"><a href="tel:02(850)2560-3">02 (850) 2560 - 3</a></h4>
                              <h6 className="mb-15"> <i > <FaEnvelopeOpen/> </i><a href="mailto:info@chistakushta.com">info@chistakushta.com</a></h6>
                              <div className="tp-footer-info-social">
                                 <a href="/"><i><FaFacebookF className='icon' /> </i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp" data-wow-delay=".6s">
                           <h4 className="tp-footer-widget-title mb-35">Our Services</h4>
                           <ul>
                              <li><a href="#">Carpet Cleaning</a></li>
                              <li><a href="#">Plumbing Serivices</a></li>
                              <li><a href="#">Park Cleaning</a></li>
                              <li><a href="#">Residential Services</a></li>
                              <li><a href="#">Toilet Cleaning</a></li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-3 mb-30 wow fadeInUp" data-wow-delay=".9s">
                           <h4 className="tp-footer-widget-title mb-35">Recent News</h4>
                           <div className="tp-footer-news">
                              <div className="tp-footer-news-single mb-15">
                                 <h6><Link to="/blogDetails">Orci magna pede, quisque sociis integer litora netus</Link></h6>
                                 <span>Jun 02, 2021</span>
                              </div>
                              <div className="tp-footer-news-single">
                                 <h6><Link to="/blogDetails">Congue morbi elit dictumst socio sit mauris congue sed.</Link></h6>
                                 <span>Jun 02, 2021</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div> */}
            <div className="tp-copyright-area-two bg-green-light z-index pt-30 pb-30">
               <div className="container">
                  <div className="row">
                     <div className="col-12">
                        <div className="tp-copyright tp-copyright-two text-center">
                           <p className="m-0">Copyright ©2023 <span>Tonsan1</span>. All Rights Reserved Copyright</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;