import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import AboutArea from '../../Home/Home/AboutArea/AboutArea';
import WorkingProcess from './WorkingProcess/WorkingProcess';

const About = () => {
    return (
        <>
            <Navigation/>
            <AboutArea />
            <WorkingProcess />
            <Footer/>
        </>
    );
};

export default About;