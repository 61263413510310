import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineGoogle } from 'react-icons/ai';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {
   const Home = <NavLink to="/">Начало</NavLink>
   const About = <NavLink to="/about">За нас </NavLink>
   const Services = <NavLink to="/services">Услуги </NavLink>
   const Contacts = <NavLink to="/contact">Контакти</NavLink>
   return (
      <>

         <div className='side__bar'>
            <Offcanvas show={show} onHide={handleClose} placement='end'>
               <Offcanvas.Header closeButton>
                  {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
               </Offcanvas.Header>
               <Offcanvas.Body>

                  {/*<Collapsible trigger={Home} triggerTagName="div"*/}
                  {/*   triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={true}>*/}
                     <ul className="mobile-nav-link text-white mt-3">
                        <li><NavLink to="/">Начало</NavLink></li>
                     </ul>
                  {/*</Collapsible>*/}

                  <ul className="mobile-nav-link text-white mt-3 mb-3">
                     <li><NavLink to="/about">За нас</NavLink></li>
                  </ul>


                  <Collapsible trigger={Services} className="mb-3" triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/homemanager">Професионален домоуправител</NavLink></li>
                        <li><NavLink to="/maintenance">Професионален домоуправител и техническа поддръжка</NavLink></li>
                        <li><NavLink to="/cleaning">Професионално почистване</NavLink></li>
                     </ul>
                  </Collapsible>

                  <ul className="mobile-nav-link text-white">
                     <li><NavLink to="/contact">Контакти</NavLink></li>
                  </ul>

                  <div className="fix">
                     <div className="side-info">
                        <div className="side-info-content">
                           <div className="tp-mobile-menu"></div>
                           <div className="contact-infos mb-30">
                              <div className="contact-list mb-30">
                                 <ul>
                                    <li><i className="flaticon-mail"></i><a href="mailto:info@klenar.com">info@chistakushta.com</a></li>
                                    <li><i className="flaticon-phone-call"></i><a href="tel:0886111330">0886 11 13 30</a></li>
                                 </ul>
                                 <div className="sidebar__menu--social">
                                    <a className="text-white" href="/"><i><FaFacebookF className='icon' /> </i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;