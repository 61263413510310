import React from 'react';
import AboutSinglePrice from '../../../components/AboutSinglePrice/AboutSinglePrice';


const PricingAffordablePackage = () => {
   return (
      <>
         <section className="tp-pricing-area pt-40 pb-90">
            <div className="container">
               <div className="row">
                  <AboutSinglePrice icon_name="blogger" title="Администрация и управление" margin="40"/>
                  <AboutSinglePrice icon_name="accounting" title="Финанси и софтуер" margin="40"/>
                  <AboutSinglePrice icon_name="cleaning" title="Професионално почистване" margin="40"/>
               </div>
               <div className="row">
                  <AboutSinglePrice icon_name="customer-support" title="Техническа поддръжка и ремонтни дейности" margin="20"/>
                  <AboutSinglePrice icon_name="law" title="Юридическа помощ" margin="40"/>
               </div>
            </div>
         </section>
      </>
   );
};

export default PricingAffordablePackage;