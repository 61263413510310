import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './pages/About/About/About';
import Appointment from './pages/Appointment/Appointment/Appointment';
import Faq from './pages/Faq/Faq/Faq';
import Services from './pages/Services/Services/Services';
import HomeManager from './pages/ServicesDetails/ServicesDetails/HomeManager';
import Maintenance from './pages/ServicesDetails/ServicesDetails/Maintenance';
import Cleaning from './pages/ServicesDetails/ServicesDetails/Cleaning';
import Contact from './pages/Contact/Contact/Contact';
import { WOW } from 'wowjs';
import ScrollToTop from './components/ScrollToTop';
import HomeThree from './pages/HomeThree/HomeThree/HomeThree';
import Documents from './pages/Documents/Documents';

const App = () => {
  // wow animation active
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeThree />} />
          <Route path="/home" element={<HomeThree />} />
          <Route path="/about" element={<About />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/services" element={<Services />} />
          <Route path="/homemanager" element={<HomeManager />} />
          <Route path="/cleaning" element={<Cleaning />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/documents" element={<Documents />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;