import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { CgCheckO } from 'react-icons/cg';

const CleaningDetails = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen}
        videoId="o4GuSJYSzrY" onClose={() => setOpen(false)} />

      <section className="tp-service-details-area pt-120 pb-90 bg-gray-light">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="tp-service-details">
                <h2 className="tp-section-title heading-color-black pt-10 mb-20">Професионално почистване</h2>
                <h5 class="tp-section-subtitle-three mb-20">От 2.50лв. на Апартамент</h5>
                <p className="mb-20">Цената е примерна, а крайната такава се определя след оглед на сградата, спрямо брой етажи, апартаменти и живущи, както и спрямо броя месечни посещения за почистване на обекта. За подробна оферта отговаряща на характеристиките на вашия вход, моля свържете се с нас.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Почистване на фоайета, етажни и стълбищни площадки</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Сухо измитане</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Мокро измиване</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Премахване на паяжини</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Почистване на асансьорни кабини (ако има такива)</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Почистване на звънци, пощенски кутии и парапети и ключове на осветлението</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Събиране и изхвърляне на отпадъци в общите части и пред входа</p>
                <p className="d-flex"> <i > <CgCheckO /> </i>Почистване на санитарни, портиерни и други общи помещения</p>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CleaningDetails;