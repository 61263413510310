import React from 'react';
import ServicePageArea from '../../../../components/ServiceArea/ServicePageArea';

const ServicesArea = () => {
   return (
      <>
         <section className="tp-service-area-three bg-gray-light">
            <div className="container">
               <div className="row pt-80">
                  <ServicePageArea service_image_num="3" ser_icon_img="blogger"
                     ser_title="Професионален домоуправител" colorclassName="home_three_title"
                     description="Професионален домоуправител, който отговаря за сградата."
                     linkTo="homemanager"></ServicePageArea>

                  <ServicePageArea service_image_num="4" ser_icon_img="customer-support"
                     ser_title="Техническа поддръжка и ремонтни дейности" colorclassName="home_three_title"
                     description="Отсраняване на неизправности като подмяна на брави на входна врата, крушки и други дребни ремонти."
                     linkTo="maintenance"></ServicePageArea>

                  <ServicePageArea service_image_num="14" ser_icon_img="cleaning"
                                   ser_title="Професионално почистване" colorclassName="home_three_title"
                                   description="Почистване на санитарни, портиерни и други общи помещения."
                                   linkTo="cleaning"></ServicePageArea>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesArea;