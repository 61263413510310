import React from 'react';
import HomeThreeAbout from './HomeThreeAbout/HomeThreeAbout';
import PricingAffordablePackage from '../../Pricing/PricingAffordablePackage/PricingAffordablePackage';
import Navigation from '../../../components/shared/Navigation/Navigation';
import Footer from '../../../components/shared/Footer/Footer';

const HomeThree = () => {
    return (
        <>
            <Navigation/>
            <HomeThreeAbout/>
            <PricingAffordablePackage/>
            <Footer/>
        </>
    );
};

export default HomeThree;