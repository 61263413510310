import React from 'react';
import Footer from '../../components/shared/Footer/Footer';
import Navigation from '../../components/shared/Navigation/Navigation';
import DocumentComponent from './DocumentComponent/DocumentComponent';

const Documents = () => {
   return (
      <>
         <Navigation />
         <DocumentComponent />
         <Footer />
      </>
   );
};

export default Documents;