import React from 'react';
import { Link } from 'react-router-dom';

const AboutArea = () => {
   return (
      <>
         <section className="tp-about-area position-relative pt-90 pb-60 fix">
            <div className="tp-about-shape">
               <img src="assets/img/about/about-shape-1.jpg" className="img-fluid" alt="img not found"/>
            </div>
            <div className="container">
               <div className="row justify-content-xl-between justify-content-md-center">
                  <div className="col-xl-5 col-12">
                     <div className="tp-about-img z-index wow fadeInUp" data-wow-delay=".3s">
                        <img src="assets/img/about/about-img-1.jpg" alt="img not found"/>
                     </div>
                  </div>
                  <div className="col-xl-6 col-md-10">
                     <div className="tp-about-text z-index wow fadeInUp" data-wow-delay=".6s">
                        <div className="section-title-wrapper mb-30">
                           <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                              За нашата компания</h5>
                           <h2 className="tp-section-title">Чиста Къща <br/>House Manager</h2>
                        </div>
                        <p className="mb-20">
                           Чиста къща House Manager е семейна компания, която ви предлага приятелско отношение, грижа и коректност.
                           
                        </p>
                        <p className="mb-20">
                           Ние сме фирма за професионално домоуправление и поддръжка на сгради от всякакво естество.
                        </p>
                        <p className="mb-20">
                           Тук сме, за да направим живота в етажната собственост по-организиран и спокоен, като Ви спестим време и финанси.
                        </p>
                        <p className="mb-40">
                           Екипът ни е млад и мотивиран и има за цел да предостави професионалното отношение, което заслужавате, както и да намерим цялостно решение за комфортното ползване на общите жилищни части на територията на град Дупница.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default AboutArea;