import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import ApointArea from './ApointArea/ApointArea';

const Appointment = () => {
    return (
        <>
            <Navigation/>
            <ApointArea />
            <Footer/>
        </>
    );
};

export default Appointment;