import React from 'react';
import { Link } from 'react-router-dom';
import HomeThreeSIngleAbout from '../../../../components/HomeThreeSIngleAbout/HomeThreeSIngleAbout';

const HomeThreeAbout = () => {
   return (
      <>
         <section className="tp-about-area-two position-relative pt-85 pb-85 bg-gray-light">
            <div className="container">
               <div className="row justify-content-xl-between justify-content-md-center">
                  <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-10">
                     <div className="tp-about-img-three position-relative mb-30 wow fadeInUp tp-about-img z-index wow fadeInUp" data-wow-delay=".3s">
                        <img src="assets/img/about/about-img-3.jpg" className="img-fluid" alt="img-not-found" />
                     </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-md-10">
                     <div className="tp-about-text tp-about-text-three wow fadeInUp" data-wow-delay=".6s">
                        <div className="section-title-wrapper mb-25">
                           <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20"> Професионален домоуправител</h5>
                           <h2 className="tp-section-title"> Чиста Къща</h2>
                        </div>
                        <p className="mb-20">
                           Доверете ни се, за да превърнем Вашия вход в едно по-чисто, по-уютно и по-добро място за живот!
                        </p>
                        <h5 className="tp-section-subtitle-three mb-20">Цени от 5лв.</h5>
                        <div className="row">
                           <div className="col-6">
                              <div className="tp-about-three-btn">
                                 <Link to="/appointment" className="theme-btn text-white">
                                    <i className="flaticon-enter"></i> Заяви оферта</Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeAbout;