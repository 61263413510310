import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { CgCheckO } from 'react-icons/cg';

const MaintenanceDetails = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen}
        videoId="o4GuSJYSzrY" onClose={() => setOpen(false)} />

      <section className="tp-service-details-area pt-120 pb-90 bg-gray-light">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="tp-service-details">
                <h2 className="tp-section-title heading-color-black pt-10 mb-20">Професионален домоуправител и техническа поддръжка</h2>
                <h5 class="tp-section-subtitle-three mb-20">От 6.99лв. на Апартамент</h5>
                <p className="mb-20">Цената за всяка етажна собственост се определя според броя на апартаментите, обема и конкретните дейности по поддръжката след направено запитване. За подробна оферта, моля свържете се с нас.</p>
                <p className="mb-35">За апартаменти с живущи пенсионери и хора в неравностойно положение – от 5,99лв.</p>
                <h3 className="tp-service-details-subtitle">Техническа поддръжка и ремонтни дейности</h3>
                <p className="d-flex"> <i > <CgCheckO /> </i> Организиране на периодични технически прегледи на входа / сградата</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Отсраняване на неизправности като подмяна на брави на входна врата, крушки и други дребни ремонти</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Осигуряване на оферти от фирми подизпълнители за планови ремонти (за които е взето решение на Общо събрание), сравняване на оферти и условия. Надзор над работата на избраните лица, организация и контрол на качеството на извършените ремонти</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Организация по отстраняване на възникнали проблеми, аварии и неотложни ремонти в ЕС.</p>
                <p className="d-flex"> <i > <CgCheckO /> </i> Организиране поддръжката на асансьори от лицензирана фирма-партньор с квалифицирани техници</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MaintenanceDetails;