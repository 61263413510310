import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import MaintenanceDetails from './ServicesDetailsFaq/MaintenanceDetails';

const Maintenance = () => {
    return (
        <>
            <Navigation />
            <MaintenanceDetails />
            <Footer />
        </>
    );
};

export default Maintenance;