import React from 'react';
import { Link } from 'react-router-dom';
import { GoDesktopDownload } from 'react-icons/go';

const ProjectItem = ({project_subtitle, project_title }) => {
   return (
      <>
         <div className="col-lg-6 col-md-6 pt-50 pb-50">
            <div className="tp-project z-index mb-30">
               <div className="tp-project-text">
                  <div className="tp-project-text-content pr-10">
                     <h4 className="tp-project-title"><Link to="/projectsDetails">{project_title}</Link></h4>
                  </div>
                  <div className="tp-project-text-icon">
                     <Link to="/projectsDetails"><i className="text-white"><GoDesktopDownload /></i></Link>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default ProjectItem;