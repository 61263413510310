import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import ServicesArea from './ServicesArea/ServicesArea';

const Services = () => {
    return (
        <>
            <Navigation/>
            <ServicesArea />
            <Footer/>
        </>
    );
};

export default Services;