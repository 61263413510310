import React,{useState} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';
import { FaBars } from 'react-icons/fa';

const Navigation = () => {
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   // sticky state
   const [sticky,setSticky] = useState(false);
   const stickyNavbar = () => {
      if(window.scrollY > 80){
         setSticky(true)
      }
      else{
         setSticky(false)
      }
   }
   window.addEventListener('scroll',stickyNavbar);
   return (
      <>
         <header>
            <div className="tp-header-area">
               <div className="tp-header-top theme-dark-bg d-none d-xl-block">
                  <div className="tp-custom-container">
                     <div className="row align-items-center">
                        <div className="col-xxl-4 col-xl-5">
                           <div className="tp-header-top-info">
                              <div className="tp-header-top-info-single">
                                 <div className="tp-header-top-info-single-icon mr-15">
                                    <i className="flaticon-mail"></i>
                                 </div>
                                 <div className="tp-header-top-info-single-text">
                                    <span className="tp-header-top-info-single-label">Имейл</span>
                                    <a href="mailto:info@klenar.com" className="tp-header-top-info-single-content font-medium text-white">info@chistakushta.com</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xxl-4 col-xl-2 pt-20 pb-60">
                           <div className="header-logo text-center">
                              <Link to="/"><img src="assets/img/logo/logo.png" className="img-fluid" alt="CHISTA KUSHTA" width="150" />
                              </Link>
                           </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5">
                           <div className="tp-header-top-info justify-content-end">
                              <div className="tp-header-top-info-single mr-85 d-flex align-items-center">
                                 <div className="tp-header-top-info-single-icon tp-header-top-info-single-icon-call mr-10">
                                    <i className="flaticon-phone-call"></i>
                                 </div>
                                 <div className="tp-header-top-info-single-text">
                                    <a href="tel:0886111330" className="tp-header-top-info-single-content font-medium text-white">0886 11 13 30</a>
                                 </div>
                              </div>
                              <div className="tp-header-top-info-single">
                                 <div className="tp-header-top-info-single-btn">
                                    <a href='https://clients.pm-pro.net/?page=cl_login&keeper=1927' target='_blank' className="yellow-btn"><i className="flaticon-enter"></i> Вход за клиенти</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className={sticky ? "sticky-menu tp-header-menu-area tp-transparent-header-menu header-sticky" :"tp-header-menu-area tp-transparent-header-menu header-sticky pt-70"}>
                  <div className="container">
                     <div className="row justify-content-xl-center align-items-center">
                        <div className="col-xl-2 col-8 tp-sticky-column">
                           <div className="tp-sticky-logo">
                              <Link to="/"><img src="assets/img/logo/logo.png" className="img-fluid" alt="Chista Kushta" width="100" /></Link>
                           </div>
                        </div>
                        <div className="col-xl-7 col-4">
                           <div className="tp-main-menu-bg">
                              <div className="tp-main-menu">
                                 <nav id="tp-mobile-menu">
                                    <ul className="text-center">
                                       <li><NavLink to="/">Начало</NavLink></li>
                                       <li><NavLink to="/about">За нас</NavLink></li>
                                       <li className="menu-item-has-children"><NavLink to="/services">Услуги</NavLink>
                                          <ul className="sub-menu">
                                             <li><NavLink to="/homemanager">Професионален домоуправител</NavLink></li>
                                             <li><NavLink to="/maintenance">Професионален домоуправител и техническа поддръжка</NavLink></li>
                                             <li><NavLink to="/cleaning">Професионално почистване</NavLink></li>
                                          </ul>
                                       </li>
                                       <li><NavLink to="/documents">Документи</NavLink></li>
                                       <li><NavLink to="/contact">Контакти</NavLink></li>
                                    </ul>
                                 </nav>
                              </div>


                              <div className="side-menu-icon d-xl-none text-end">
                                 <button onClick={handleShow} className="side-toggle border-0 bg-transparent">
                                 <i> <FaBars className='bar_icon' /> </i></button>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3 tp-sticky-column-btn">
                           <div className="tp-sticky-btn text-end">
                              <a href='https://clients.pm-pro.net/?page=cl_login&keeper=1927' target='_blank' className="yellow-btn"><i className="flaticon-enter"></i> Вход за клиенти</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose}></Sidebar>
      </>
   );
};

export default Navigation;