import React from 'react';
import { Link } from 'react-router-dom';

const WorkingProcess = () => {
   return (
      <>
         <section className="tp-feature-area-three pt-90 pb-80 bg-gray-light">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title-wrapper text-center mb-45 wow fadeInUp" data-wow-delay=".2s">
                        <h2 className="tp-section-title mb-25 heading-color-black">Защо да ни изберете?</h2>
                     </div>
                  </div>
               </div>
               <div className="row justify-content-center">
                  <div className="col-xl-4 col-sm-6">
                     <div className="tp-feature-three text-center mb-30 wow fadeInUp" data-wow-delay=".4s">
                        <div className="tp-feature-three-text">
                           <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">Приятелско отношение</h4>
                           <p>Гарантираме ви приятелско отношение и мотивация по пътя към създаването на максимално хармонична атмосфера в Етажната собственост.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-sm-6">
                     <div className="tp-feature-three text-center mb-30 wow fadeInUp" data-wow-delay=".7s">
                        <div className="tp-feature-three-text">
                           <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">Събираемост</h4>
                           <p>Подобряване на събираемостта на месечните вноски.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-sm-6">
                     <div className="tp-feature-three text-center mb-30 wow fadeInUp" data-wow-delay="1s">
                        <div className="tp-feature-three-text">
                           <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">Прозрачност</h4>
                           <p>Прозрачност при управлението на бюджета на входа, чрез представяне на регулярни отчети.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-sm-6">
                     <div className="tp-feature-three text-center mb-30 wow fadeInUp" data-wow-delay="1.3s">
                        <div className="tp-feature-three-text">
                           <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">Прецизност</h4>
                           <p>Прецизно водене на необходимата документация, съобразно нормативните изисквания.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-sm-6">
                     <div className="tp-feature-three text-center mb-30 wow fadeInUp" data-wow-delay="1.3s">
                        <div className="tp-feature-three-text">
                           <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">Организация</h4>
                           <p>Надеждно и качествено организиране на подобренията (ремонтите) в общите части, при наличие на решение на Общото събрание.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-4 col-sm-6">
                     <div className="tp-feature-three text-center mb-30 wow fadeInUp" data-wow-delay="1.3s">
                        <div className="tp-feature-three-text">
                           <h4 className="tp-feature-three-title mb-20 heading-color-black-with-hover">Опит</h4>
                           <p>Опит при разрешаване на различни възникнали казуси и проблеми.</p>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12">
                        <div className="tp-about-text text-center wow fadeInUp" data-wow-delay=".2s">
                           <p className="mb-20 mt-40">
                              Предлагаме ви комплексни решения и безупречно управление, което ще покрие всички ваши нужди и изисквания!
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default WorkingProcess;