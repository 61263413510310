import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import ContactForm from './ContactForm/ContactForm';

const Contact = () => {
   return (
      <>
         <Navigation />
         <ContactForm />
         <Footer />
      </>
   );
};

export default Contact;